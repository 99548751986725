import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LBgSect,
  CPageNav,
  CFaqList,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '/faq/',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">ケータリングについて</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: 'ケータリングとデリバリーはどう違うのですか?',
                a: (
                  <>
                    ケータリングは、デリバリーのようにお料理をお届けするだけでなく、会場設営から当日のサービス、後片づけまでをトータルで行うサービスです。お皿やグラス、テーブルなど備品のご用意、コンパニオン、演奏者、エンターテイナー
                    などの手配も含め、パーティー全体をコーディネートいたします。
                  </>
                ),
              },
              {
                q: '依頼できるエリアの制限はありますか?',
                a: (
                  <>
                    横浜を中心にご案内しておりますが、それ以外の地域も対応可能な場合がございます。会場選びにつきましてもお手伝いいたしますので、お気軽にご相談ください。
                  </>
                ),
              },
              {
                q: '料理、飲物以外に料金はかかりますか?',
                a: (
                  <>
                    お料理、お飲物のほか、サービススタッフの人件費、機材費、スタッフや機材の運送料が発生します。内容に応じてお見積りいたしますので、お気軽にお問合せください。
                  </>
                ),
              },
              {
                q: '何日前までに予約をすればよいですか?',
                a: (
                  <>
                    パーティーの内容によって異なりますが、開催日の1か月前を目安にご依頼ください。
                  </>
                ),
              },
              {
                q: '当日のクロークは対応してもらえますか?',
                a: (
                  <>
                    承ります。混雑する会場でも臨機応変に対応できるプロを置けばスムーズな運営が可能です（別途費用が発生します）。
                  </>
                ),
              },
              {
                q: '料理の内容を指定することはできますか?',
                a: (
                  <>
                    季節によりご案内できないメニューもございますが、可能な限りご希望に沿って提案いたします。食物アレルギーをお持ちの方やベジタリアンの方への対応、信仰上のご要望などにつきましても、事前にご相談ください。
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">周年記念について</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '宴席当日までの手続きや打合せはどのようになっていますか?',
                a: (
                  <>
                    お打合せや各種ご手配は、お申込み直後から開催日まで5段階のスケジュールに沿って進めさせていただきます。詳しくはお問合せください。
                  </>
                ),
              },
              {
                q: '招待状は主催者側で用意するのですか?',
                a: (
                  <>
                    招待状の文面のサンプルを元に、主催の皆様のご意向を反映させながら、印刷から宛名書きまでを一式で手配することが可能です。なお、招待者のリスト作成は準備に時間を要しますので、お早めにご準備されることをおすすめいたします。
                  </>
                ),
              },
              {
                q: '祝儀は預かっていただけますか?',
                a: (
                  <>
                    フロントのセーフティボックスにてお預けいただけます。会場担当者がフロントまでご案内いたしますので、必要の際にお申付けください。なお、セーフティボックスの開閉を行えるのは、預けたご本人のみとなりますのでご注意ください。
                  </>
                ),
              },
              {
                q: '招待状に宴会形式を明記したいのですが、どう表現すればよいですか?',
                a: (
                  <>
                    招待状の文末に「当日は着席形式でご用意～」「当日は立食形式でご用意～」と記載するのが一般的です。なお、会費制の場合は本状に具体的な金額を明記し、ご祝儀制の場合は何も記載しなくて構いません。また、カジュアルなパーティーの場合は「平服でお越しください」と文末に明記するのが一般的です。
                  </>
                ),
              },
              {
                q: '身支度する場所をホテルで用意してもらえますか?',
                a: (
                  <>
                    宴会棟B1Fに更衣室がございます。ご予約は不要ですので、自由にご利用いただけます。
                    <br />
                    貸切りをご希望の場合は、別途お部屋のご用意も可能です。（有料）
                    <br />
                    また、宴席前日および当日のご宿泊、衣裳のレンタルやヘア&メイク・着付けのご予約も承ります。お気軽にお申付けください。
                  </>
                ),
              },
              {
                q: '引出物や記念品の手配はできますか?',
                a: (
                  <>
                    お客様のご要望に応じて、さまざまな商品をご提案いたします。熨斗や専用の持帰り袋も手配いたしますので、ご相談ください。
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">法要について</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '「お別れの会」と「偲ぶ会」の違いは何ですか?',
                a: (
                  <>
                    厳密な定義はございませんが、「お別れの会」はご葬儀から
                    1か月半以内に行うのが一般的です。一方
                    「偲ぶ会」は、忌明け後や1年後、あるいはそれ以上経ってから行われることもございます。
                  </>
                ),
              },
              {
                q: '小規模（少人数）でもお別れの会、偲ぶ会は行えますか?',
                a: (
                  <>
                    さまざまな規模のお別れの会・偲ぶ会のお手伝いをさせていただいております。長年培ったノウハウときめ細やかなサポートにより、お客様の不安を解消しながらご希望に沿ったご提案をいたしますので、お気軽にお問合せください。
                  </>
                ),
              },
              {
                q: '料理はどのような内容がいいのでしょうか?',
                a: (
                  <>
                    立食のブッフェスタイルが一般的で、幅広い年齢層の方に対応できるフランス料理・日本料理・中国料理の折衷料理が好まれます。また、故人のお好きだったメニューなど、ご希望に合わせてご用意することも可能です。
                  </>
                ),
              },
              {
                q: '祭壇は用意してもらえますか?',
                a: (
                  <>
                    豊富なバリエーションをご用意しております。大きさ、お色味、お花の種類など、ご遺族や主催の皆様のご希望を伺いながら、故人や企業イメージに合うオリジナルの祭壇をご提案させていただきます。
                  </>
                ),
              },
              {
                q: 'ホテルでお別れの会を行うメリットは?',
                a: (
                  <>
                    広々とした快適な空間、きめ細やかなサービス、クオリティの高いお料理など、ホテルならではの価値をご提供いたします。また、ご案内状や引き物などの準備から、ご列席者のご宿泊や当日の着付けまで、ホテルに一括でお任せいただけますので、主催者様のご負担を軽減することができます。
                  </>
                ),
              },
              {
                q: 'お別れの会や偲ぶ会に適した服装はどのようなものですか?',
                a: (
                  <>
                    実行委員長・喪主の方は正喪服（洋装の場合は男性はモーニング、女性はブラックフォーマル）、ご親族や会を運営される方々は略礼服、参会される方々は平服が一般的です。
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">その他</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: 'パーティーを考えているのですが、どこに問合せればよいですか?',
                a: (
                  <>
                    宴会に関するご案内、宴会場の空き状況等につきましては、セールス部にて承っております。詳しくはセールス部へお問合せください。
                    <br />
                    個人・法人のお客様：TEL
                    045-221-1000（10:00～18:00／平日のみ）
                    <br />
                    各代理店様：<a href="tel:0452245122">TEL 045-224-5122</a>
                    （10:00～18:00／平日のみ）
                  </>
                ),
              },
              {
                q: 'サービス料はいくらですか?',
                a: <>本体価格の15%を頂戴いたします。</>,
              },
              {
                q: '車で行きたいのですが、駐車場はありますか?また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル専用スペースではございませんが、駐車場がございます。
                    <br />
                    詳しくは
                    <Link to="/about/access/car/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '羽田空港と成田空港へのアクセスはどのようになりますか?',
                a: (
                  <>
                    ホテルより直行バスが運行しております。
                    <br />
                    詳しくは
                    <Link to="/about/access/bus/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '出席者の中で食べ物にアレルギーや食事制限のある人がいます。対応できますか?',
                a: (
                  <>
                    食物アレルギー、持病、宗教、妊娠、授乳中などの食事制限によりお召しあがりいただけない食材がある場合は、事前にお申付けください。
                    <br />
                    <ul className="c_noteList">
                      <li>
                        すべてのアレルギー等に対応できるわけではございませんので、予めご了承ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: 'wifiは利用できますか?',
                a: (
                  <>
                    ホテルご利用のお客様は無料でご利用いただけます。
                    <br />
                    詳しくは
                    <Link to="/facilities/business/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
